import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/cross.png'


const _hoisted_1 = { class: "modal-overlay" }
const _hoisted_2 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_3 = { class: "reg_body" }
const _hoisted_4 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_t = _resolveDirective("t")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "close_button",
        src: _imports_0,
        alt: "Panera",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
      }),
      _withDirectives(_createElementVNode("h2", null, null, 512), [
        [_directive_t, 'download_modal.headline_copy']
      ]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("p", null, null, 512), [
          [_directive_t, 'download_modal.body_copy']
        ]),
        _createElementVNode("a", {
          class: "button",
          href: `https://eprize-content.s3.amazonaws.com/panera/eras/socialShare/${_ctx.$store.state.profile.era}.png`,
          download: "",
          onClick: _cache[1] || (_cache[1] = $event => ($options.trackClick()))
        }, _toDisplayString(_ctx.$t('buttons:download')), 9, _hoisted_4)
      ])
    ])
  ]))
}