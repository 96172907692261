import store from './store/index';

async function setRecaptchaV2Challenge ({ data }) {
    try {
        // Make sure recaptcha is loaded
        await new Promise(resolve => grecaptcha.ready(resolve));

        data['g-recaptcha-response'] = grecaptcha.getResponse();
    }
    catch (err) { console.error('getting recaptchaV2 response', err, String(err)); }
}

async function setRecaptchaV3Challenge ({ data, action }) {
    try {
        // Make sure recaptcha is loaded
        await new Promise(resolve => grecaptcha.ready(resolve));

        if (!store.state.app.recaptchaV3PublicKey) console.warn('did not find recaptchaV3PublicKey in store');
        data['g-recaptcha-response-v3'] = await grecaptcha.execute(store.state.app.recaptchaV3PublicKey, { action });
        return data;
    }
    catch (err) { console.error('getting recaptchaV3 response', err, String(err)); }
}

export {
    setRecaptchaV2Challenge,
    setRecaptchaV3Challenge,
};
