
import axios from 'axios';
import { reactive } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    responses: [],
    personality: null,
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateSurveyResponses (state, responses) {
        state.responses = responses;
    },
    updatePersonality (state, personality) {
        state.personality = personality;
    },
};

const actions = {
    async submitSurvey ({ dispatch }, responses) {
        const grecaptcha = await setRecaptchaV3Challenge({ data: {}, action: 'survey' });
        const response = await dispatch('makeCall', {
            endpoint: 'surveys/era/responses',
            responses,
            grecaptcha,
        });
        await dispatch('award/awardEvent', { event: 'survey' }, { root: true });
    },

    async makeCall ({ state, dispatch, commit }, {
        type = 'post',
        endpoint,
        responses = [],
        grecaptcha = '',
    }) {
        try {
            const { data } = await axios[type](`${api.base}/${endpoint}`, { responses, ...grecaptcha });
            commit('updateSurveyResponses', data.responses);
            commit('updatePersonality', data.personality);
            commit('profile/updateProfile', { era: data.personality }, { root: true });
        }
        catch (err) {
            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            throw err;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
