
    import BaseModal from './BaseModal';

    export default {
        name: 'RegConfirmModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'RegConfirm',
            };
        },
        i18nOptions: { namespaces: ['modal_content', 'buttons'] },
    };
