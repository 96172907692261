
    import { mapState } from 'vuex';
    import { track } from '../plugins/WebAnalytics';
    import BaseModal from './BaseModal';

    export default {
        name: 'DownloadModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'Download',
            };
        },
        computed: {
            ...mapState(['profile']),
        },
        methods: {
            trackClick () { // v-track wasn't working, so did this alternative here.
                track('click', { category: 'download button', label: 'download' });
            },
        },
        i18nOptions: { namespaces: ['modal_content', 'buttons'] },
    };
