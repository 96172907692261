
    import BaseModal from './BaseModal';

    export default {
        name: 'PersonalityQuizModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'PersonalityQuiz',
                isModalVisible: true,
            };
        },
        mounted () {
            setTimeout(() => {
                this.isModalVisible = false;
            }, 5000);
        },
        i18nOptions: { namespaces: ['modal_content'] },
    };
